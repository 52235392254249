<template>
  <div style="height: 100%">
    <!-- loading animation -->
    <template v-if="!loaded">
      <v-container fluid style="height: 100%">
        <v-row align="center" justify="center" style="height: 100%">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- data content -->
    <template v-else>
      <v-container>
        <v-row class="mt-5">
          <v-col>
            <h1 class="text-center text-h3 mt-5 font-weight-bold">KARMA 360</h1>
            <p class="text-center ma-5">
              {{ $t("dashboardContent") }}
            </p>
            <div class="text-center">
              <v-btn class="ma-5 font-weight-bold" to="/docs">{{
                $t("document")
              }}</v-btn>
              <v-btn
                color="primary"
                href="https://vizuro.com/contact"
                target="_blank"
                class="font-weight-bold"
                >{{ $t("contact") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="mt-10 px-16">
        <v-row dense>
          <!-- datasets card -->
          <v-col cols="12" md="6" lg="4" class="px-4">
            <v-card>
              <!-- title -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="datsetsCard.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ datsetsCard.title }}
                </v-list-item-content>
              </v-list-item>
              <!-- content -->
              <template v-if="datsetsCard.content">
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in datsetsCard.content"
                      :key="i"
                      dense
                      link
                      @click="$router.push(`/datasetGroups/${item.id}`)"
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-checkbox-marked-circle </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="
                            formatTimeToClientTimezone(
                              item.created_at.split('.')[0]
                            )
                          "
                        ></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </template>
              <template v-else>
                <v-card-subtitle v-text="datsetsCard.text"></v-card-subtitle>
              </template>

              <!-- action -->
              <template v-if="datsetsCard.action">
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    text
                    :to="`${datsetsCard.action.path}`"
                    color="primary"
                  >
                    <v-icon>{{ datsetsCard.action.icon }}</v-icon>
                    <span>{{ datsetsCard.action.text }}</span>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-col>

          <!-- models card -->
          <v-col cols="12" md="6" lg="4" class="px-4">
            <v-card>
              <!-- title -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="modelsCard.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ modelsCard.title }}
                </v-list-item-content>
              </v-list-item>

              <!-- content -->
              <template v-if="modelsCard.content">
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in modelsCard.content"
                      :key="i"
                      dense
                      link
                      @click="onClickModelItem(item)"
                    >
                      <v-list-item-icon>
                        <v-icon v-if="item.step === 'Done'">
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else> mdi-cog-clockwise </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="item.step"
                        ></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </template>
              <template v-else>
                <v-card-subtitle v-text="modelsCard.text"></v-card-subtitle>
              </template>

              <!-- action -->
              <template v-if="modelsCard.action">
                <v-divider></v-divider>
                <!-- <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    @click.stop="isModelCreateActive = !isModelCreateActive"
                  >
                    <v-icon>{{ modelsCard.action.icon }}</v-icon>
                    <span>{{ modelsCard.action.text }}</span>
                  </v-btn>
                </v-card-actions> -->
              </template>
            </v-card>
          </v-col>

          <!-- errors card -->
          <v-col cols="12" md="6" lg="4" class="px-4">
            <v-card>
              <!-- title -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="errorsCard.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ errorsCard.title }}
                </v-list-item-content>
              </v-list-item>

              <!-- content -->
              <template v-if="errorsCard.content">
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in errorsCard.content"
                      :key="i"
                      dense
                      link
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-checkbox-marked-circle </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="item.created_at"
                        ></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </template>
              <template v-else>
                <v-card-subtitle v-text="errorsCard.text"></v-card-subtitle>
              </template>

              <!-- action -->
              <template v-if="errorsCard.action">
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn text :to="`${errorsCard.action.path}`" color="primary">
                    <v-icon>{{ errorsCard.action.icon }}</v-icon>
                    <span>{{ errorsCard.action.text }}</span>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-col>

          <!-- model create -->
          <model-create
            v-if="isModelCreateActive"
            v-model="isModelCreateActive"
          ></model-create>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import data from "@/models/data";
import { formatTimeToClientTimezone } from "@/utils";

export default {
  name: "Dashboard",
  components: {
    ModelCreate: () => import("@/components/model/ModelCreate.vue"),
  },
  data: () => ({
    loaded: true,
    datsetsCard: {
      title: "Recent projects",
      text: "Collect and prepare your dataset to train a model",
      content: null,
      action: {
        icon: "mdi-plus",
        text: "CREATE PROJECT",
        path: "/datasetGroups/create",
      },
      icon: "mdi-database-outline",
    },
    modelsCard: {
      title: "Train your model",
      text: "Train a machine learning model with your dataset",
      content: null,
      action: {
        icon: "mdi-plus",
        text: "TRAIN NEW MODEL",
        path: "",
      },
      icon: "mdi-cube-outline",
    },
    errorsCard: {
      title: "Recent errors",
      text: "No error occurred",
      content: null,
      action: null,
      icon: "mdi-alert-octagram-outline",
    },
    isModelCreateActive: false,
  }),
  methods: {
    async getDatasetGroups() {
      let res = await data.getDatasetGroups();
      res = res.data.DatasetGroup;
      if (res.length !== 0) {
        // filter the recent 5 dataset groups
        res.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        res.splice(5);
        this.datsetsCard.content = res;
      }
    },
    async getModels() {
      let res = await data.getModels();
      res = res.data.models;
      if (res.length !== 0) {
        // filter the recent 5 models
        res.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        res.splice(5);
        this.modelsCard.content = res;
      }
    },
    formatTimeToClientTimezone,
  },
  created() {
    this.getDatasetGroups();
    this.getModels();
  },
};
</script>

<style scoped></style>
